import { css } from "@emotion/react";
import { graphql } from "gatsby";
import { CallToAction, Section } from "../components";
import { StyleShape4 } from "../components/Shapes";
import Theme from "../components/Theme";
import { PageLayout } from "../layouts/Page";
import { CallToActionData } from "../queries/DataTypes";
import { SectionComparisonTable, SectionComparisonTableProps } from "../sections/ComparisonTable";

const styles = {
  base: css`
    position: relative;
    ::before {
      ${StyleShape4};
      z-index: ${Theme.zIndex.header + 1};
      transform: rotate(165deg);
      top: -780px;
      left: calc(50% - 860px);
      opacity: 0.5;
    }
  `,
};

type PricingTier = "free" | "premium";
interface PageProps {
  data: {
    mdx: {
      frontmatter: {
        title: string;
        description: string;
        keywords: string;
        hero: CallToActionData;
      } & SectionComparisonTableProps<PricingTier>;
    };
  };
}

export default ({
  data: {
    mdx: {
      frontmatter: { title, description, keywords, hero, footnote, tiers, groups },
    },
  },
}: PageProps) => (
  <PageLayout title={title} description={description} keywords={keywords}>
    <header css={styles.base}>
      <Section marginTop marginBottom>
        <CallToAction width="50%" appearance="secondary" alignment="center" {...hero} />
      </Section>
    </header>
    <main>
      <SectionComparisonTable footnote={footnote} tiers={tiers} groups={groups} />
    </main>
  </PageLayout>
);

export const query = graphql`
  query PricingQuery {
    mdx(fileAbsolutePath: { glob: "**/pages/pricing.md" }) {
      frontmatter {
        title
        description
        keywords
        hero {
          heading
          content
        }
        footnote
        tiers {
          type
          name
          action {
            text
            href
          }
          heading
          content
          price
          priceNote
          recommended
        }
        groups {
          active
          name
          items {
            active
            name
            description
            href
            free
            premium
          }
        }
      }
    }
  }
`;
